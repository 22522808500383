import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/auth-login/auth-login.module').then(
        m => m.AuthLoginModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./modules/auth-login/register.module').then(
        m => m.RegisterModule
      ),
  },
  {
    path: 'edit-form',
    loadChildren: () =>
      import('./modules/edit-form/edit-form.module').then(
        m => m.EditFormModule
      ),
  },
  {
    path: 'create-form',
    loadChildren: () =>
      import('./modules/create-form/create-form.module').then(
        m => m.CreateFormModule
      ),
  },

  {
    path: 'request-data',
    loadChildren: () =>
      import('./modules/auth-login/form-edit.module').then(
        m => m.FormEditModule
      ),
  },
];
